import Hls from "hls.js";
import flvjs from "flv.js";
import * as THREE from "three/build/three.module";

var videoElement;
var hlsPlayer;
var flvPlayer;
var videoTex;
var oldMtl;
var curScreen;
var curMtl;

class screenVideo{
    constructor(){
        videoElement = document.createElement('video');
        this.videoElement = videoElement;
        videoElement.setAttribute('id','videoElement');
        videoElement.setAttribute('crossOrigin','Anonymous');
        videoElement.setAttribute('webkit-playsinline','true');
        videoElement.setAttribute('playsinline','true');
        videoElement.setAttribute('loop','true');
        videoTex = new THREE.VideoTexture(videoElement);
        this.videoTex = videoTex;
        if (videoElement) {
            videoElement.oncanplay = () => {
                //videoTex?.dispose();
                if(curScreen){
                    //videoTex = new THREE.VideoTexture(videoElement);
                    curScreen.material.map = videoTex;
                }
                videoElement.play();
            };
        }
    }

    setScreen(screen){
      if(screen != curScreen){
        curScreen = screen;
        curMtl?.dispose();
        if(screen && screen.material){
          oldMtl = screen.material;
          curMtl = oldMtl.clone();
          curScreen.material = curMtl;
        }
      }
    }

    playVideo(url){
      try {
        videoElement.src = "";
        videoElement.srcObject = null;
        if (url) {
          if(curScreen && curMtl){
            curScreen.material = curMtl;
          }
          if (flvPlayer) {
            flvPlayer.detachMediaElement();
            flvPlayer.destroy();
            flvPlayer = null;
          }
          if (hlsPlayer) {
            hlsPlayer.detachMedia();
            hlsPlayer.destroy();
            hlsPlayer = null;
          }
          let bPlay = false;
          let arrStr = url.split("?");
          if (arrStr && arrStr.length > 0) {
            let len = arrStr[0].length;
            if (len < 4) return;
            let name = arrStr[0].substr(len - 3, 3);
            if (name == "flv" && flvjs.isSupported()) {
              flvPlayer = flvjs.createPlayer({ type: "flv", url: url });
              flvPlayer.attachMediaElement(videoElement);
              flvPlayer.load();
              flvPlayer.play();
              console.log(flvPlayer);
              bPlay = true;
            }
            else if (url.indexOf("mp4") >= 0) {
              videoElement.src = url;
              bPlay = true;
            }
            name = arrStr[0].substr(len - 4, 4);
            if (name == "m3u8") {
              // var is_mobile =
              //   navigator.userAgent
              //     .toLowerCase()
              //     .match(
              //       /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
              //     ) != null;
              let u = navigator.userAgent;
              let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
              if (isiOS) {
                videoElement.src = url;
                bPlay = true;
              } else if (Hls.isSupported()) {
                hlsPlayer = new Hls();
                hlsPlayer.attachMedia(videoElement);
                hlsPlayer.loadSource(url);
                videoElement.play();
                bPlay = true;
              }
            }
            if (!bPlay) {
              if (url.indexOf("video") == 0) {
                let video = document.getElementById(url);
                if (video) {
                  videoElement.srcObject = video.srcObject;
                  bPlay = true;
                }
              }
            }
          }
          if (bPlay) {
            videoElement.play();
            return;
          }
        }
        if(curScreen && oldMtl){
          curScreen.material = oldMtl;
        }
        videoElement.pause();
      } catch (e) {
        console.log(e);
      }
    }
}

export {screenVideo}